import $ from 'jquery';
import PerfectScrollbar from 'perfect-scrollbar/dist/perfect-scrollbar.esm';

const activeRegionSemantics = {
    citiesSearchDropdownSelector: '.js-cities-search-dropdown',
    cityElementSelector: '.js-city-select',
    cookieName: 'activeRegionId',
    regionChangeUrlTemplate: '/geo/region/change?regionId={regionId}',
};

$(() => {
    let activeRegion = new ActiveRegion();
    activeRegion.subscribe();
});

/**
 * TODO[needImpl]: такой код нельзя оставлять - переработать
 * Причина - код под какой-то "виджет" из прошлого проекта
 * Сервисы так не выглядят (см. примеры сервисов в этом же каталоге)
 */
class ActiveRegion {

    subscribe() {

        var self = this;

        $('body').on('click', function(e) {
            if ($(e.target).closest('.js-cities-search-form').length < 1) {
                $(activeRegionSemantics.citiesSearchDropdownSelector).hide();
            }
            return true;
        });

        $('body').on('click', activeRegionSemantics.cityElementSelector, function(e) {
            e.preventDefault();
            let href = $(this).attr('href');
            $.ajax({
                url: activeRegionSemantics.regionChangeUrlTemplate.replace('{regionId}', $(this).attr('data-id')),
                success: function(data) {
                    if (data.status === true) {
                        window.location.href = href;
                    }
                },
                error: function() {
                    return true;
                }
            });
            // e.stopPropagation();
        });
        
        const cdScrollbar = new PerfectScrollbar($(
            activeRegionSemantics.citiesSearchDropdownSelector
        )[0]);

        $('.js-cities-search').on('focus', function(e) {
            const $dropdown = $(
                activeRegionSemantics.citiesSearchDropdownSelector
            );

            if ($(this).val() != '') {
            $dropdown.show();
            }
        });

        $('.js-cities-search').on('keyup', function(e) {
            let value = $(this).val().toLowerCase();
            const $form = $(this).closest('form');
            const $dropdown = $form.find(
                activeRegionSemantics.citiesSearchDropdownSelector
            );


            // console.log(e.keyCode);
            if (e.keyCode == 40) {
                if ($dropdown.find('a._active').length < 1) {
                    $dropdown.find('a:first-child').addClass('_active');
                } else {
                    $dropdown.find('a._active').removeClass('_active').next().addClass('_active');
                    // $dropdown.scrollTop = $dropdown.find('a._active').offset().top;
                    // $dropdown.animate({ scrollTop: $dropdown.find('a._active').offset().top }, 1000);
                }
                return false;
            } else if (e.keyCode == 38) {
                if ($dropdown.find('a._active').length < 1) {
                    $dropdown.find('a:last-child').addClass('_active');
                } else {
                    $dropdown.find('a._active').removeClass('_active').prev().addClass('_active');
                    // $dropdown.scrollTop = $dropdown.find('a._active').offset().top;
                    // $dropdown.animate({ scrollTop: $dropdown.find('a._active').offset().top }, 1000);
                }
                return false;
            }

            $dropdown.html('');
            $dropdown.show();
            //mvp
            $('.cities-popup__content').find('.modal-window-region-switcher-v2__wrap ul li a').each(function() {
            let text = $(this).text();
            if (text.toLowerCase().indexOf(value) > -1) {
                $dropdown.append(`
                <a href="${$(this).attr('href')}" class="js-city-select" 
                data-domain="${$(this).attr('data-domain')}" 
                data-id="${$(this).attr('data-id')}">${text}</a>
                `);
            }
            });

            if ($dropdown.find('a').length < 1) {
            $dropdown.append('<div class="_nothing">Ничего не найдено</div>');
            }

            cdScrollbar.update();
        });

        $('.js-cities-search-form').on('submit', function(e) {
            const $dropdown = $(
                activeRegionSemantics.citiesSearchDropdownSelector
            );
            let cityId = false;
            if ($dropdown.find('a._active').length > 0) {
            cityId = $dropdown.find('a._active').attr('data-id');
            } else if($dropdown.find('a').length > 0) {
            cityId = $dropdown.find('a:first-child').attr('data-id');
            }
            // sendChangeSityRequest(cityId, false);

            return false;
        });

    }
}
