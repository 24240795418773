import {MarketingEventTypeEnum} from "./MarketingEventTypeEnum";

// Список доступных параметров для каждого события
/**
 * @see common/enums/bi/EventTypeEnum.php
 * @see common/enums/bi/EventVariableTypeEnum.php
 * */
export const MarketingEventAllowedVariables = {
    [MarketingEventTypeEnum.addToCart]: [
        'productType',
        'message'
    ],
    [MarketingEventTypeEnum.productBuy]: [
        'baseCardId'
    ],
};

/**
 * Валидирует dynamicData для заданного eventTypeId
 * @param {number} eventTypeId - Идентификатор типа события
 * @param {Object} dynamicData - Объект данных события
 * @returns {boolean} - true, если данные валидны, иначе выбрасывает исключение
 */
export function validateDynamicData(eventTypeId, dynamicData) {
    const allowedVariables = MarketingEventAllowedVariables[eventTypeId];
    if (!allowedVariables) {
        throw new Error(`Неизвестный eventTypeId: ${eventTypeId}`);
    }

    // так динамк дата не обзятелен, если его нет пропускаем проверку
    if (!dynamicData) {
        return true;
    }

    for (const key of Object.keys(dynamicData)) {
        if (!allowedVariables.includes(key)) {
            throw new Error(
                `Недопустимый параметр "${key}" для события с eventTypeId ${eventTypeId}. Разрешены: ${allowedVariables.join(', ')}`
            );
        }
    }
    return true;
}