import { ClickByHandler } from '../services/bi/MarketingEventHandlers/ClickByHandler.js';
/** @see common/enums/bi/EventHandlerTypeEnum */
// Типы маркетинговых событий хендлеров
export const MarketingEventHandlerTypeEnum = {
    1 : {
        name: 'clickBy',
        handler: ClickByHandler
    },
    2: {
        name: 'sendForm',
        handler: null
    },
    3: {
        name: 'openWindow',
        handler: null
    },
    4: {
        name: 'pageView',
        handler: null
    },
    5: {
        name: 'loaded',
        handler: null
    },
}
