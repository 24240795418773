import $ from 'jquery';

/**
 * FooterV2 widget configs
 */
const footerV2Semantics = {
    'mainWrapClass': 'footer-v2',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'collapseGroupClass': 'collapse-title',
    'collapseContentClass': 'collapse-content',
};


$(function () {
    FooterV2.init();
})

class FooterV2 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${footerV2Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.footerV2) {
            window.widgets.footerV2 = new FooterV2();
        }

        /**
         * Checking other instances
         */
        $(`.${footerV2Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${footerV2Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.footerV2.registerInstance($(el));
                window.widgets.footerV2.registerListeners($(el));
            }
        });
    }

    /**
     * Register instance
     * @throws
     * @param el
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${footerV2Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            let object = el.find(`.${footerV2Semantics.mainWrapClass}`);
            el.attr(`${footerV2Semantics.isInitializedAttribute}`, true);
            this.pluginInstances[instanceId] = object;
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    registerListeners(el) {
         let elements = $(`.${footerV2Semantics.mainWrapClass}[${
                footerV2Semantics
                        .instanceIdAttribute
        }="${
                el.attr(footerV2Semantics
                        .instanceIdAttribute)
        }"]`);
        elements.on(
            'click',
            `.${footerV2Semantics.collapseGroupClass}`,
            function (e) {
                e.preventDefault();
                e.stopPropagation();
                $(this).siblings(`.${footerV2Semantics.collapseContentClass}`).toggle();
            });
    }
}