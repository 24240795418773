import $ from 'jquery';
import {compareArray} from "../../scripts/old-scripts/compare";
import {favoriteArray} from "../../scripts/old-scripts/favorite";
import PerfectScrollbar from '../../scripts/perfect-scrollbar.min';

/**
 * SearchBarV1 widget configs
 */
const searchBarV1Semantics = {
    'mainWrapClass': 'search-bar-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',

    //пока старые метки
    searchInputClass: 'js-search-input',
    searchFormClass: 'js-search-form',
    searchFormClose: 'js-searchform-close',
    searchEndPoint: '/site/suggest',

    'debounceTime': 500,
};


$(function () {
    SearchBarV1.init();
})

class SearchBarV1 {
    constructor() {
        this.pluginInstances = {};
        this.debounceTimer = null;
    }

    static init() {
        if ($(`.${searchBarV1Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.searchBarV1) {
            window.widgets.searchBarV1 = new SearchBarV1();
        }

        /**
         * Checking other instances
         */
        $(`.${searchBarV1Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${searchBarV1Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.searchBarV1.registerInstance($(el));
                window.widgets.searchBarV1.registerInstanceListeners($(el));
            }
        });
    }

    /**
     * Register instance
     * @throws
     * @param el
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${searchBarV1Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            let object = el.find(`.${searchBarV1Semantics.mainWrapClass}`);
            el.attr(`${searchBarV1Semantics.isInitializedAttribute}`, true);
            window.widgets.searchBarV1.pluginInstances[instanceId] = object;
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    registerInstanceListeners(el) {
        let ps = null;
        el.find(`.${searchBarV1Semantics.searchInputClass}`)
            .on('focus', function () {
                $(this).closest(`.${searchBarV1Semantics.searchFormClass}`).addClass('_focused');
                if (!ps) {
                    if ($(this).closest(`.${searchBarV1Semantics.searchFormClass}`).find('.js-search-good-list').length > 0) {
                        const goodList = $(this)
                            .closest(`.${searchBarV1Semantics.searchFormClass}`)
                            .find('.js-search-good-list')[0];

                        setTimeout(() => {
                            ps = new PerfectScrollbar(goodList, {wheelPropagation: false});
                        }, 0);
                    }
                } else {
                    ps.update();
                }
            })
            .on('input', (event) => {
                const value = event.target.value;
                const $this = $(event.target);

                // запрос
                if (!value) {
                    return;
                }

                this.debounceSearch($this);
            });

        // закрывает поиск при клике вне элемента
        $(document).on('click', function (e) {
            const $target = $(e.target);
            if ($target.closest(`.${searchBarV1Semantics.searchFormClass}`).length) {
                return
            }

            $(`.${searchBarV1Semantics.searchFormClass}`).removeClass('_focused');
        });

        $(`.${searchBarV1Semantics.searchFormClose}`).on('click', function (e) {
            $(`.${searchBarV1Semantics.searchFormClass}`).removeClass('_focused');
            e.preventDefault();
            $('.js-search-form input').val('');
        });

        $(`.${searchBarV1Semantics.searchFormClass}`).on('submit', function (e) {
            let value = $(this).find(`.${searchBarV1Semantics.searchInputClass}`).val();
            if (value === '') {
                e.preventDefault();
            }
        });
    }

    debounceSearch($this) {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(
            () => {
                this.loadFromServer($this);
            },
            // Задержка в 500мс перед отправкой последнего запроса
            searchBarV1Semantics.debounceTime
        );
    }

    loadFromServer($this) {
        $.get(searchBarV1Semantics.searchEndPoint,
            {'term':$this.val()},
            function (data) {
            //console.log(data);
            if (data['status']) {

                $('.search-dropdown').html(data['output']);

                if (typeof compareIds !== 'undefined') {
                    compareArray(compareIds);
                }
                if (typeof wishListIds !== 'undefined') {
                    favoriteArray(wishListIds);
                }

                if ($this.closest(`.${searchBarV1Semantics.searchFormClass}`).find('.js-search-good-list').length > 0) {
                    const goodList = $this.closest(`.${searchBarV1Semantics.searchFormClass}`).find('.js-search-good-list')[0];
                    setTimeout(() => {
                        let ps = new PerfectScrollbar(goodList, {wheelPropagation: false});
                    }, 0);
                }

                if (typeof compareIds !== 'undefined') {
                    compareArray(compareIds);
                }
                if (typeof wishListIds !== 'undefined') {
                    favoriteArray(wishListIds);
                }
            }
        });
    }
}