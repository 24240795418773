// Общий исполняемый код, выполняемый автоматически 
// при загрузке любой страницы

// TMP variables
window.widgets = {};
window.attachFiles = {};

RegExp.escape = function(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

String.prototype.mapReplace = function (replacements) {
    var regexTokens = [];

    for (var prop in replacements) {
        regexTokens.push(RegExp.escape(prop));
    }

    regex = new RegExp(regexTokens.join('|'), "g");

    if (regexTokens.length) {
        return this.replace(regex, function(match) {
            return replacements[match];
        });
    }
    return this;
}