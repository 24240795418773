function favoriteArray(wishListIds) {
    if (wishListIds instanceof Array){
        var indexC;
        for (indexC = 0; indexC < wishListIds.length; ++indexC) {
            var button = $('.js-favorite-add[data-product-id="'+wishListIds[indexC]+'"]');

            button.closest('.js-card').addClass('_favorite');
            button.addClass('_active'); // не для карты
            button.addClass('js-favorite-del');
            button.removeClass('js-favorite-add');

        }
    }
}

function setFavoriteInfo(count) {
    if(count > 0) {
      $('.main-favorite .site-header__indicators-count').removeClass('hidden');
    } else {
      $('.main-favorite .site-header__indicators-count').addClass('hidden');
    }
    $('.main-favorite .site-header__indicators-count span').text(count);
}


function addToFavoriteHandler(e) {
    e.preventDefault()
    var button = $(this);
    $.post('/wishlist/add/' + $(this).data('productId'),
        null,
        function(data){
            if (data['status']) {
                setFavoriteInfo(data['count']);

                button.closest('.js-card').toggleClass('_favorite');
                button.toggleClass('_active'); // не для карты
                button.addClass('js-favorite-del');
                button.removeClass('js-favorite-add');

                if($('.wishlist-index').length > 0) {
                  window.location.reload();
                }
            }
        }
    );
    return false;
}

function delFromFavoriteHandler(e) {
  e.preventDefault()
    var button = $(this);
    $.post('/wishlist/delete/' + $(this).data('productId'),
        null,
        function(data){
            if (data['status']) {
                setFavoriteInfo(data['count']);

                button.closest('.js-card').toggleClass('_favorite');
                button.toggleClass('_active'); // не для карты
                button.removeClass('js-favorite-del');
                button.addClass('js-favorite-add');
            }
        }
    );
    return false;
}

export {
    delFromFavoriteHandler,
    addToFavoriteHandler,
    setFavoriteInfo,
    favoriteArray
};
