import $ from 'jquery';
import 'slick';
import { 
    slickCommonOptions,
} from './../../scripts/config';

/**
 * SliderV5 widget configs
 */
const sliderV5Semantics = {
    'mainWrapClass': 'slider-v5__wrap',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'commonPluginOptionsAttribute': 'data-common-plugin-options',
    'controlPrevClass': 'slider-v5__prev',
    'controlNextClass': 'slider-v5__next',
    'sliderElementClass': 'slider-v5__list-place', 
};

/**
 * SliderV5 initializer
 */
$(function() {
    SliderV5.init();
});

class SliderV5
{
    // not install @babel/plugin-proposal-class-properties
    // init properties in constructor
    constructor() {
        this.pluginInstances = {};
    }

    static init(reInit = false) {
        let instances = $(`.${sliderV5Semantics.mainWrapClass}`);
        if (instances.length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.shareV1) {
            window.widgets.sliderV5 = new SliderV5();
        }
        /**
         * Checking other instances
         */
        instances.each((index, el) => {
            let isInitialized =
                ($(el).attr(`${sliderV5Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized || reInit) {
                window.widgets.sliderV5.registerInstance($(el));
            }
        });
    }

    /**
     * Register instance
     * @param JQuery element
     * @throws
     */
    registerInstance(el) {
        let instanceId = 
            el.attr(`${sliderV5Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }
        let commonPluginOptions = 
            el.data(
                `${sliderV5Semantics.commonPluginOptionsAttribute}`.replace('data-', '')
            ) || {};

        let pluginOptions = Object.assign({},
            slickCommonOptions,
            commonPluginOptions,
            {
                arrows: true,
                dots: true,
                variableWidth: false,
                mobileFirst: true,
                swipeToSlide: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                adaptiveHeight: true,
                prevArrow: `.${sliderV5Semantics.mainWrapClass}` + 
                    `[${sliderV5Semantics.instanceIdAttribute}="` + 
                    el.attr(`${sliderV5Semantics.instanceIdAttribute}`) + 
                    `"] .${sliderV5Semantics.controlPrevClass}`,
                nextArrow: `.${sliderV5Semantics.mainWrapClass}` + 
                    `[${sliderV5Semantics.instanceIdAttribute}="` + 
                    el.attr(`${sliderV5Semantics.instanceIdAttribute}`) + 
                    `"] .${sliderV5Semantics.controlNextClass}`,
            }
        );
        el.show();
        try {
            // TODO[dependDetected]: slick plugin
            // the better solution: impl pluginProvider
            let slick = el.find(
                `.${sliderV5Semantics.sliderElementClass}`
            ).slick(pluginOptions);
            el.attr(`${sliderV5Semantics.isInitializedAttribute}`, true);
            window.widgets.sliderV5.pluginInstances[instanceId] = slick;
        } catch(e) {
            el.hide();
            throw e;
        }
    }

    reInit(instanceId) {
        SliderV5.init(true);
    }
}