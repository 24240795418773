import $ from 'jquery';
import { WishlistModule } from "../../services/wishlist/Wishlist";

const addToWishListV1Semantics = {
    'initiatorElementSelector': '[add-to-wishlist-v1]',
    'isInWishlistAttributeName': 'data-add-to-wishlist-v1-is-in-wishlist',
    'productTypeIdAttributeName': 'data-add-to-wishlist-v1-product-type-id',
    'idAttributeName': 'data-add-to-wishlist-v1-id',
    'virtualModifierIdAttributeName': 'data-add-to-wishlist-v1-virtual-modifier-id',

};

$(function () {
    let addToWishlistV1 = new AddToWishlistV1();
    addToWishlistV1.subscribe();
    addToWishlistV1.checkSelected();
    window.widgets.addToWishListV1 = addToWishlistV1;
});

class AddToWishlistV1 {
    subscribe() {
        let self = this;
        $(document).on(
            'click',
            addToWishListV1Semantics.initiatorElementSelector,
            function (e) {
                e.preventDefault();
                let initiatorElement = $(e.currentTarget);

                WishlistModule.list.addOrChange(
                    initiatorElement.attr(
                        addToWishListV1Semantics
                            .productTypeIdAttributeName
                    ),
                    initiatorElement.attr(
                        addToWishListV1Semantics
                            .idAttributeName
                    ),
                    initiatorElement.attr(
                        addToWishListV1Semantics
                            .virtualModifierIdAttributeName
                    ),
                    function () {
                        let selector = `[${addToWishListV1Semantics.idAttributeName}="${initiatorElement.attr(addToWishListV1Semantics.idAttributeName)}"]`;
                        selector += `[${addToWishListV1Semantics.productTypeIdAttributeName}="${initiatorElement.attr(addToWishListV1Semantics.productTypeIdAttributeName)}"]`;
                        selector += `[${addToWishListV1Semantics.virtualModifierIdAttributeName}="${initiatorElement.attr(addToWishListV1Semantics.virtualModifierIdAttributeName)}"]`
                        self.toggleElement(
                            $(selector)
                        )
                    }
                );
            }
        );
    }

    checkSelected() {
        if (!(WishlistModule?.list?.getProductSet?.() instanceof Object)) {
            return;
        }
        let productSet = WishlistModule.list.getProductSet().products;
        for (let key in productSet) {

            let selector = `[${addToWishListV1Semantics.idAttributeName}="${productSet[key]['id']}"]`;

            if (productSet[key]['productTypeId']) {
                selector += `[${addToWishListV1Semantics.productTypeIdAttributeName}="${productSet[key]['productTypeId']}"]`;
            }

            if ('virtualModifier' in productSet[key]) {
                let virtualModifierValue = productSet[key]['virtualModifier'] ? productSet[key]['virtualModifier'] : '';
                selector += `[${addToWishListV1Semantics.virtualModifierIdAttributeName}="${virtualModifierValue}"]`;
            } else {
                selector += `:not([${addToWishListV1Semantics.virtualModifierIdAttributeName}]), [${addToWishListV1Semantics.virtualModifierIdAttributeName}=""]`;
            }

            let foundElement = $(selector);
            if (foundElement.length) {
                this.toggleElement(foundElement, true);
            }
        }
    }

    toggleElement(elements, state) {
        $.each(elements, function (key, element) {
            let $element = $(element);
            let toggleVal;
            if (typeof state !== 'undefined') {
                toggleVal = state ? "1" : "0";
            }
            else {
                toggleVal = $element.attr(addToWishListV1Semantics.isInWishlistAttributeName) === "1" ? "0" : "1";
            }
            $element.attr(addToWishListV1Semantics.isInWishlistAttributeName, toggleVal);
        });
    }
}