import $ from 'jquery';

const mainMenuV3Semantics = {
    // да v1 не ошибка.
    'mainWrapClass': 'main-menu-v1',
    'instanceIdAttribute': 'data-instance-id',
    'isInitializedAttribute': 'data-is-initialized',
    'actionHoverElement' :'menu-bar__item',
    'loaderElementClass' : 'main-menu-v1__loader',
    // 50ms у меня работает на всякий случай запас ставлю
    'triggerTimeout' : 150,
};

$(() => {
    MainMenuV3.init();
});

class MainMenuV3 {
    constructor() {
        this.pluginInstances = {};
    }

    static init() {
        if ($(`.${mainMenuV3Semantics.mainWrapClass}`).length === 0) {
            return false;
        }

        if (!window || !window.widgets || !window.widgets.mainMenuV3) {
            window.widgets.mainMenuV3 = new MainMenuV3();
        }

        /**
         * Checking other instances
         */
        $(`.${mainMenuV3Semantics.mainWrapClass}`).each((index, el) => {
            let isInitialized =
                ($(el).attr(`${mainMenuV3Semantics.isInitializedAttribute}`) === 'true');
            if (!isInitialized) {
                window.widgets.mainMenuV3.registerInstance($(el));
                window.widgets.mainMenuV3.registerListeners($(el));
            }
        });
    }

    /**
     * Register instance
     * @throws
     * @param el
     */
    registerInstance(el) {
        let instanceId =
            el.attr(`${mainMenuV3Semantics.instanceIdAttribute}`) || false;

        if (instanceId === false) {
            throw new Error(`Invalid jQuery selector for ` +
                `instance slider with id [${instanceId}]`);
        }

        try {
            let object = el.find(`.${mainMenuV3Semantics.mainWrapClass}`);
            el.attr(`${mainMenuV3Semantics.isInitializedAttribute}`, true);
            window.widgets.mainMenuV3.pluginInstances[instanceId] = object;
            // забираем данные с сервера.
            this.loadDataFromServer(el);
        } catch (e) {
            el.hide();
            throw e;
        }
    }

    registerListeners(el) {
        // переделал на css но на всякий случай оставляю. так как не всегда срабатывает, когда мышка уже на элементе
        // el.on('mouseenter', `.${mainMenuV3Semantics.actionHoverElement}`, (event) => {
        //     el.find('.' + mainMenuV3Semantics.loaderElementClass).show();
        // })
        // el.on('mouseleave', `.${mainMenuV3Semantics.actionHoverElement}`, (event) => {
        //     el.find('.' + mainMenuV3Semantics.loaderElementClass).hide();
        // })
    }

    // тянем данные с сервера
    loadDataFromServer(el) {
        $.ajax({
            method: 'POST',
            url: '/main-menu-data',
            headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr("content")},
            dataType: 'json',
            success:  (data) => {
                el.after(data.html);
                this.loadScripts(data.script, () => {
                    // Сбрасываем наведение и инициируем его повторно
                    el.find(`.${mainMenuV3Semantics.actionHoverElement}:hover`).each(function () {
                        $(this).css('pointer-events', 'none');
                        // Триггерим родительский контейнер, чтобы событие точно сработало
                        let parent = $(this).closest('.menu-bar__wrap');
                        parent.css('pointer-events', 'none');
                        setTimeout(() => {
                            // удаляем картинку с лоадером
                            el.find('.' + mainMenuV3Semantics.loaderElementClass).remove();
                            if (parent.length) {
                                parent
                                    .css('pointer-events', '')
                                    .trigger('mouseleave')
                                    .trigger('mouseout')
                                    .trigger('mouseenter')
                                    .trigger('mouseover')
                                ;
                            }

                            $(this)
                                .css('pointer-events', '')
                                .trigger('mouseleave')
                                .trigger('mouseenter')
                                .trigger('mouseenter')
                                .trigger('mouseover')
                        },
                            mainMenuV3Semantics.triggerTimeout
                        );
                    });
                });
            },
            error: function (xhr, status, error) {
                console.error('Ошибка при загрузке саб меню:', error);
            }
        })
    }

    loadScripts(scripts, callback) {
        let loadedScripts = 0;

        scripts.forEach(function (src) {
            let script = document.createElement('script');
            script.src = src;
            script.type = 'application/javascript';
            script.async = true;

            // Добавляем обработчик события для отслеживания полной загрузки скрипта
            script.onload = function () {
                loadedScripts++;  // Увеличиваем счетчик загруженных скриптов
                if (loadedScripts === scripts.length) {
                    // Все скрипты загружены
                    // Вызываем переданный callback после загрузки всех скриптов
                    // console.log("дергаем коллбэк триггер таймаут: ", mainMenuV3Semantics.triggerTimeout+" ms");
                    callback();
                }
            };

            // Обрабатываем ошибки загрузки скриптов (опционально)
            script.onerror = function () {
                loadedScripts++;
                console.error(`Ошибка загрузки скрипта: ${src}`);
            };

            document.body.appendChild(script);  // Добавляем скрипт в документ
        });
    }
}