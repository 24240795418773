function compareArray(compareIds) {
    if (compareIds instanceof Array){
        var indexC;
        for (indexC = 0; indexC < compareIds.length; ++indexC) {
            var button = $('.js-compare-add[data-product-id="'+compareIds[indexC]+'"]');

            button.closest('.js-card').addClass('_compare');
            button.addClass('_active'); // не для карты
            button.addClass('js-compare-del');
            button.removeClass('js-compare-add');

        }
    }
}

function setCompareInfo(count) {
    if(count > 0) {
      $('.main-compare .site-header__indicators-count').removeClass('hidden');
    } else {
      $('.main-compare .site-header__indicators-count').addClass('hidden');
    }
    $('.main-compare .site-header__indicators-count span').text(count);
}


function addToCompareHandler(e) {
    e.preventDefault()
    var button = $(this);
    $.post('/compare/add/' + $(this).data('productId'),
        null,
        function(data){
            if (data['status']) {
                setCompareInfo(data['count']);

                button.closest('.js-card').toggleClass('_compare');
                button.toggleClass('_active'); // не для карты
                button.addClass('js-compare-del');
                button.removeClass('js-compare-add');

                if($('.compare-index').length > 0) {
                  window.location.reload();
                }
            }
        }
    );
    return false;
}

function delFromCompareHandler(e) {
  e.preventDefault()
    var button = $(this);
    $.post('/compare/delete/' + $(this).data('productId'),
        null,
        function(data){
            if (data['status']) {
                setCompareInfo(data['count']);

                button.closest('.js-card').toggleClass('_compare');
                button.toggleClass('_active'); // не для карты
                button.removeClass('js-compare-del');
                button.addClass('js-compare-add');
            }
        }
    );
    return false;
}

export {
    delFromCompareHandler,
    addToCompareHandler,
    setCompareInfo,
    compareArray
};
