import $ from 'jquery';
import LIVR from 'livr';
import ErrorCodes from '../../services/validator/ErrorCodes';
import Validator from '../../services/validator/Validator';
import { initPopup } from '../../scripts/initPopup';

const semantics = {
    'wrapClass': 'modal-window-order-call-v1',
    'formName': 'CtaRequestV1Form',
    'formItemClass': 'form__input-item',
    'errorWrapClass': 'invalid-feedback',
};

let validatorRules = {
    [`${semantics.formName}[phone]`]: ['required', 'russian_phone'],
};

const validator = new LIVR.Validator(validatorRules);

$(function() {
    let modalWindowOrderCallV1 = new ModalWindowOrderCallV1();
    modalWindowOrderCallV1.subscribe();
});

class ModalWindowOrderCallV1 {
    constructor() {
        this.modalWindowEl = $(`.${semantics.wrapClass}`);
    }

    resetError() {
        this.modalWindowEl
            .find(`.${semantics.formItemClass} input, .${semantics.formItemClass} textarea`)
            .removeClass('_error');
        this.modalWindowEl
            .find(`.${semantics.formItemClass} .${semantics.errorWrapClass}`)
            .removeClass('show');
    }

    showError(validator) {
        Object.entries(validator.getErrors()).forEach(([name, errCode]) => {
            const field = this.modalWindowEl.find(`[name="${name}"]`);
            if (field.length) {
                switch (errCode) {
                    case ErrorCodes.REQUIRED:
                        field.addClass('_error');
                        break;
                    default:
                        field
                            .closest(`.${semantics.formItemClass}`)
                            .find(`.${semantics.errorWrapClass}`)
                            .addClass('show')
                            .empty()
                            .html(
                                $('<p>', {
                                    class: '_alert _alert-error',
                                }).html(Validator.generateError(errCode))
                            );
                        break;
                }
            }
        });
    }

    subscribe() {
        let self = this;

        $(this.modalWindowEl).on('submit', 'form', function(e) {
            e.preventDefault();
            self.resetError();
            const $form = $(this);
            const formData = new FormData(this);
            const formDataObj = {};
            formData.forEach((value, key) => (formDataObj[key] = value));

            const isValidForm = validator.validate(formDataObj);
            if (!isValidForm) {
                self.showError(validator);
                return false;
            }

            $.ajax({
                url: this.action,
                method: this.method,
                processData: false,
                contentType: false,
                cache: false,
                data: formData,
                success: function(data) {
                    const successPopup = $form.closest('.js-form').find('.js-success-popup');
                    $.magnificPopup.open(initPopup.prototype.getConfig(successPopup));
                },
                error: function(data) {
                    const failPopup = $form.closest('.js-form').find('.js-error-popup');
                    $.magnificPopup.open(initPopup.prototype.getConfig(failPopup));
                },
            });
        });
    }
}
