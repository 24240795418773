/* eslint-disable-next-line no-console */
const globalSematics = {
    'popupStackCacheSelector': '#popup-stack-cache'
}

const size = {
    tablet: 768,
    smDesktop: 1025,
    desktop: 1280,
    laptop: 1360,
    desktopMax: 1604,
};

// понадобился какой-то общий класс для работы с размерами экранов.
// это часто используется, вот наконец-то выношу эту логику
class ScreenMode {
    // Метод для автоматического определения режима экрана
    static getWindowMode() {
        const windowWidth = window.innerWidth;

        // Получаем ключи из объекта size и сортируем их по возрастанию значений
        const sizeEntries = Object.entries(size).sort((a, b) => a[1] - b[1]);

        // Проходим по ключам и находим текущий режим
        for (let i = 0; i < sizeEntries.length; i++) {
            const [mode, width] = sizeEntries[i];
            if (windowWidth < width) {
                // Если меньше первого размера, это мобильный режим
                return i === 0 ? 'mobile' : sizeEntries[i - 1][0];
            }
        }

        // Если ширина больше всех значений в size, возвращаем последний режим
        return sizeEntries[sizeEntries.length - 1][0];
    }

    // Универсальный метод для проверки конкретного режима экрана
    static isMode(modeName) {
        const windowWidth = window.innerWidth;

        // Получаем соответствующую ширину для режима
        const currentModeWidth = size[modeName];

        // Проверка, существует ли такой режим
        if (!currentModeWidth)
            return false;

        // Сортируем размеры по возрастанию
        const sizeEntries = Object.entries(size).sort((a, b) => a[1] - b[1]);

        // Находим индекс текущего режима
        const modeIndex = sizeEntries.findIndex(([mode]) => mode === modeName);

        // Проверяем, соответствует ли ширина текущему режиму
        const minWidth = modeIndex === 0 ? 0 : sizeEntries[modeIndex - 1][1];
        return windowWidth >= minWidth && windowWidth < currentModeWidth;
    }
}

// https://kenwheeler.github.io/slick/
// https://github.com/kenwheeler/slick/blob/v1.8.1/slick/slick.js#L555
// buildRows:
// rows (int) Default: 1
// Setting this to more than 1 initializes grid mode. 
// Use slidesPerRow to set how many slides should be in each row.
// slidesPerRow (int) Default: 1 
// With grid mode intialized via the rows option, 
// this sets how many slides are in each grid row.
// В slick.js (searchBy:[Slick.prototype.buildRows]) в buildRows
// все элементы слайдера всегда оборачиваются в двойной div, 
// независимо ни от каких других опций (если не трогать опции rows и slidesPerRow)
// Помогает только установка параметра rows в 0 (тупо отключаем работу метода buildRows)
// поэтому данную опцию мы прокидываем автоматически во все виджеты
// как зависимость и при агрегации объекта конфигурации (pluginOptions) 
// добавляем этот объект
const slickCommonOptions = {
    rows: 0,
};

const globalConfig = {
    isDebugMode: false,
};

export {
    size,
    slickCommonOptions,
    globalConfig,
    ScreenMode,
    globalSematics,
};