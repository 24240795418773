import $ from 'jquery';
import ServerConfig from '../serverConfigFromHtml/ServerConfigFromHtml';

const semantics = {
    'wrapClass': 'ya-widget',
    'ypWidgetIdAttribute': 'data-yp-widget-id',
    'ypConfigAttribute': 'data-yp-config',
    'ypSuccessCallbackAttribute': 'data-yp-success-callback',
    'ypErrorCallbackAttribute': 'data-yp-error-callback',
};

// last callback success
// $('.cart-form-v1').find('input[name="CartV1Form[paymentType]"][value="${props.paymentType}"]').attr('checked','checked').trigger('change');
// $('.cart-form-v1').trigger('submit');
// return true;

// last callback error
// console.error('Payment error — ' + reason);

// плюс передать в paymentConfig:
// {
//     totalAmount: (float)
//     availablePaymentMethods: (string[]),
// }

const yp = {

    /**
     * В формате:
     * key => value
     */
    activePaymentSessions: new Map(),

    onYaPayLoad() {
        $(`.${semantics.wrapClass}`).each((i, e) => {
            createPaymentSession(
                e[0],
                e.attr(semantics.ypConfigAttribute) || {},
                e.attr(semantics.ypSuccessCallbackAttribute) || (() => {}),
                e.attr(semantics.ypErrorCallbackAttribute) || (() => {})
            );
        });
    },

    /**
     * @param HTMLElement widgetWrapperHtmlElement
     * @param object paymentConfig 
     * @param function paymentSuccessCallback 
     * @param function paymentErrorCallback 
     */
    createPaymentSession(
        widgetWrapperHtmlElement,
        paymentConfig,
        paymentSuccessCallback,
        paymentErrorCallback
    ) {
        let self = this;
        const YaPay = window.YaPay;

        // Покажем статус загрузки формы
        widgetWrapperHtmlElement.innerHTML = `
            <div class="button-preloader">
                <div class="loader">
                    <img src="/img/preloader.gif" class="lazyload" alt="preloader">
                </div>
            </div>
        `;

        // Данные платежа
        const paymentData = Object.assign(
            {
                env: YaPay.PaymentEnv.PRODUCTION,
                version: 4,
                currencyCode: YaPay.CurrencyCode.Rub,
                merchantId: ServerConfig['yandexPay']['apiKey'] || '',
            },
            paymentConfig
        )

        YaPay.createSession(paymentData, {
            onPayButtonClick: paymentSuccessCallback,
            onFormOpenError: paymentErrorCallback,
        })
        .then(function(paymentSession) {
            if (!paymentSession) {
                throw new Error('No payment YaPay session in YaPay service');
            }

            self.activePaymentSessions.set(
                $(widgetWrapperHtmlElement).attr(semantics.ypWidgetIdAttribute),
                paymentSession
            );
            paymentSession.unmountWidget(widgetWrapperHtmlElement);

            paymentSession.mountWidget(
                widgetWrapperHtmlElement,
                {
                    widgetType: YaPay.WidgetType.BnplPreview,
                }
            );
        })
        .catch(function (err) {
            console.error(err);
        });
    },

    /**
     * @param function widgetWrapperHtmlElement
     */
    resetPaymentSession(
        widgetWrapperHtmlElement
    ) {
        if (this.activePaymentSessions.get(
            $(widgetWrapperHtmlElement).attt(semantics.ypWidgetIdAttribute)
        ) != undefined) {
            this.activePaymentSessions.get(
                $(widgetWrapperHtmlElement).attt(semantics.ypWidgetIdAttribute)
            ).destroy();
            this.activePaymentSessions.delete(
                $(widgetWrapperHtmlElement).attt(semantics.ypWidgetIdAttribute)
            );
        }
    }

}

window.yp = yp;

export default yp;