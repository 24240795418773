import {biMarketingEventRunner} from './BiMarketingEventRunner';
import {validateDynamicData} from "../../enums/MarketingEventAllowedVariables";

/**
 * WithBiMarketingEventRunner - Интерфейс для использования BiMarketingEventRunner.
 * сервис прослойка.
 * @param {Object} config - Конфигурация запуска.
 * @param {number|string} [config.eventId] - ID события.
 * @param {number|string} config.eventTypeId - Тип события.
 * @param {Object} [config.dynamicData] - Динамические данные для выполнения.
 */
export function biMarketingEventRunnerRun(config) {
    const { eventTypeId, dynamicData } = config;

    // Валидация параметром через MarketingEventTypeEnum
    try {
        validateDynamicData(eventTypeId, dynamicData);
    } catch (error) {
        console.error(`Ошибка валидации: ${error.message}`);
        throw error;
    }
    // Запуск через BiMarketingEventRunner
    return biMarketingEventRunner.run(config);
}