import {MarketingEventHandlerTypeEnum} from "../../enums/MarketingEventHandlerTypeEnum";
import {MarketingEventTypeEnum} from "../../enums/MarketingEventTypeEnum";
import $ from 'jquery';

class BiMarketingEventRunner {
    constructor() {
        // Объект для хранения активных хендлеров
        this.activeHandlers = {};
        this.cache = {}
    }

    // Метод для регистрации события на элемент
    registerEvent(event) {
        const eventConfig = MarketingEventHandlerTypeEnum[event.eventType];
        if (eventConfig && eventConfig.handler) {
            // Проверяем, был ли уже создан хендлер
            let handler = this.activeHandlers[event.eventType];
            if (!handler) {
                // Создаём новый экземпляр хендлера
                handler = new eventConfig.handler();
                this.activeHandlers[event.eventType] = handler;
            }

            // если вдруг js код приходит с сервера заранее
            // сразу добавляем его в кэщ
            if (event.jsCode) {
                // Инициализируем кэш, если его ещё нет
                this.jsCodeCache = this.jsCodeCache || {};
                // Сохраняем jsCode в кэш
                this.jsCodeCache[event.id] = event.jsCode;
            }

            // Регистрируем событие
            handler.register(event, (e) => {
                // Вызов handleEvent с обработкой через промис и возращаем обязательно промис
                return this.handleEvent(e, event, handler)
                    .then(() => {
                        // ну и в лог можно добавить на всякий случай.
                        // console.log('Маркетинговое событие успешно завершено');
                    })
                    .catch((error) => {
                        console.error('Ошибка выполнения маркетингового события:', error);
                    });
            });
        } else {
            console.warn(`Нет обработчика для события: ${event.eventType}`);
        }
    }


    // публичный общий метод для получения всех событий и регистрации их
    registerAllEvents(events) {
        events.forEach((event) => {
            this.registerEvent(event);
        });
    }

    // Реализация выполнения события
    handleEvent(e, event, handler) {
        // Кэш для хранения jsCode по id событий
        this.jsCodeCache = this.jsCodeCache || {};

        return new Promise((resolve, reject) => {

            // Проверяем, есть ли код в кэше для вызываемого события
            const cachedJsCode = this.jsCodeCache[event.id];

            if (cachedJsCode) {
                // Если код уже закэширован, выполняем его сразу
                // console.log('Используем кэшированный jsCode для события', event.id);
                handler.execute(event, cachedJsCode);
                resolve();
                return;
            }

            $.ajax({
                method: 'POST',
                url: `/bi/event/get-event`,
                headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr("content")},
                data: 'eventId=' + event.id,
                success: (response) => {
                    const {jsCode} = response;

                    // добавляем в кэш код для повтороного использования
                    this.jsCodeCache[event.id] = jsCode;

                    // и выполняем jsCode для зарегистрированного элемента
                    handler.execute(event, jsCode);
                    resolve();
                },
                error: (xhr, status, error) => {
                    console.error('Ошибка при получении jsCode:', error);
                    reject(error);
                }
            });
        });
    }

    // пока не понятна структура конфига.
    // пока кейс который вытягивается по eventId (может добавить код?)
    async run(config) {
        const { eventId = null, eventTypeId, dynamicData = {} } = config;

        if (!eventTypeId) {
            console.error('Не указан eventTypeId для события.');
            return;
        }

        // Проверяем кэш
        if (this.cache[eventTypeId]) {
            this.executeCollection(this.cache[eventTypeId], dynamicData);
            return;
        }

        try {
            const bodyData = new URLSearchParams({
                eventTypeId: encodeURIComponent(eventTypeId),
            });
            if (eventId) {
                bodyData.append('eventId', encodeURIComponent(eventId));
            }
            // Делаем AJAX-запрос на сервер за кодом
            const response = await fetch('/bi/event/event-run', {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': $('meta[name="csrf-token"]').attr("content"),
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: bodyData.toString(),
            });

            if (!response.ok) {
                throw new Error('Ошибка загрузки события');
            }

            const { events } = await response.json();

            if (Array.isArray(events) && events.length > 0) {
                // Кэшируем всю коллекцию событий для eventTypeId
                this.cache[eventTypeId] = events;
                // Выполняем коллекцию событий
                this.executeCollection(events, dynamicData);
            } else {
                console.warn(`Не найдено событий для eventTypeId ${eventTypeId}`);
            }
        } catch (error) {
            console.error('Ошибка выполнения события:', error);
        }
    }

    // исполняет код для коллекции
    executeCollection(events, dynamicData = {}) {
        if (!Array.isArray(events)) {
            console.error('Передана некорректная коллекция событий.');
            return;
        }

        for (const event of events) {
            const {jsCode, id = ''} = event;

            if (jsCode) {
                try {
                    this.executeCode(jsCode, dynamicData);
                } catch (error) {
                    console.error(`Ошибка выполнения события id ${id}:`, error);
                }
            } else {
                console.warn(`Некорректное событие в коллекции:`, event);
            }
        }
    }
    // выполняет загруженный код, создавая локальную область видимости, чтобы можно
    // было прокидовать динамические данные
    executeCode(jsCode, dynamicData = {}) {
        try {
            // Создаём массив переменных и их значений
            const keys = Object.keys(dynamicData);
            const values = Object.values(dynamicData);

            // Создаём функцию, которая принимает переменные как параметры
            const dynamicFunction = new Function(...keys, jsCode);

            // Вызываем функцию, передавая значения из dynamicData
            dynamicFunction(...values);
        } catch (error) {
            console.error('Ошибка выполнения JavaScript-кода:', error);
        }
    }

}

export const biMarketingEventRunner = new BiMarketingEventRunner();